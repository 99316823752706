import React from 'react';
import { graphql } from 'gatsby';
import { Content } from '@allthingswww/client-act-shared';

import Layout from '../components/Layout';
import titleIcon from '../images/headerIcon.png';

export const TributePageTemplate = ({ data, location }) => {
  const { frontmatter, html } = data.page;
  const { title, subtitle, featuredImage } = frontmatter;

  return (
    <Layout
      title={title}
      subtitle={subtitle}
      headerBackGroundImage={featuredImage}
      condensed
      icon={titleIcon}
      meta={{ data, location }}
    >
      <Content source={html} />
    </Layout>
  );
};

export default TributePageTemplate;

export const pageQuery = graphql`
  query TributePageMdx($id: String!) {
    page: mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        subtitle
        featuredImage
      }
    }
  }
`;
